.manage-user-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .log-list {
    display: flex;
    flex-direction: column;

    .log-list--item {
      display: grid;
      grid-template-columns: 120px 200px auto;
      margin: 0.8em 0;

      .tag-width {
        max-width: 80px;
      }

      p {
        margin: 0;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .user-stats-and-info {
    display: flex;
    // justify-content: space-between;

    .stats-input-width {
      max-width: 120px;
    }
  }

}

@media (max-width:1300px) and (min-width:781px){
  .manage-user-container{
    width: 70vw;
  }
}

.website-task-list {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow: scroll;

  .website-task-list--item {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.internal-link-summary-cell {
  position: relative;
  max-width: 300px;
  cursor: pointer;

  .summary-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .summary-tooltip {
      display: none;
      position: absolute;
      left: 0;
      top: 100%;
      background: white;
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 8px;
      z-index: 1000;
      width: max-content;
      max-width: 400px;
      white-space: normal;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover .summary-tooltip {
      display: block;
  }
}

@media (max-width:500px){
  .container-content{
    flex-direction: column !important;
  }
  .user-account-btns{
    flex-direction: column !important;
    gap: 0.5em;
  }
}

@media (max-width:390px){
  .user-stats-and-info{
    flex-direction: column;
    place-items: flex-start;
  }
  .user-stats{
    margin: 0 !important;
  }
}


// user website button should not overlap
@media (max-width:570px){
  .user-button{
    flex-direction: column !important;
    align-items: center;
    text-align: center;
    gap: 1em;
  }
}